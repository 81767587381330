/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import XLSX from '@sheet/core';
import { CENSUS_DASH_ID } from 'Dashboard/premiumDashNames';
import { getAllIndicators, postPatientListAPI, downloadXLSX, getRecentOrgStatus } from '@api/api';
import { getLogger } from '@util/logger';
import { asyncForEach } from '@util/utilFunctions';
import { setShowNotification } from '@global-state/redux/notificationSlice';
import { PREMIUM_DASH } from '../../Dashboard/dashDataPropTypes/currentDashPropType';

const log = getLogger('dashboardSlice');

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashError: undefined,
    // TODO: the issue w misaligned modal content is that the async calls are staggering,
    // so modal title is set and then patient list is set after for ex.
    selected: undefined,
    currentDash: { id: CENSUS_DASH_ID, type: PREMIUM_DASH },
    // modalContent is an array of objects of shape: {
    // display: '',
    // content: variable based on components, proptypes outlined for each in modalComponents
    // filter: a string describing filter type that is optional
    // }
    modalContent: [],
    // an index
    selectedMonth: 0,
    updatedTime: undefined,
    dashMenuExpanded: {
      premium: true,
      custom: true,
      org: true,
    },
    hamburgerOpen: false,
    monthIndexMap: undefined,
    allIndicators: [],
  },
  reducers: {
    setDashError(state, action) {
      state.dashError = action.payload;
    },

    // sets the currently selected dashboard item (ie CKD, DIABETES)
    setSelected(state, action) {
      state.selected = action.payload;
    },

    setCurrentDash(state, action) {
      state.currentDash = action.payload;
    },

    addModalContent(state, action) {
      state.modalContent = action.payload;
    },

    resetModalContent(state, action) {
      state.modalContent = action.payload;
      state.selected = undefined;
    },

    setSelectedMonth(state, action) {
      state.selectedMonth = action.payload;
    },

    setUpdatedTime(state, action) {
      state.updatedTime = action.payload.fallIncidentsUnformatted;
    },

    setDashMenuExpanded(state, action) {
      const { menu, val } = action.payload;
      state.dashMenuExpanded[menu] = val;
    },

    setHamburgerOpen(state, action) {
      state.hamburgerOpen = action.payload;
    },
    setMonthIndexMap(state, action) {
      state.monthIndexMap = action.payload;
    },
    setAllIndicators(state, action) {
      state.allIndicators = action.payload;
    },
  },
});

export default dashboardSlice.reducer;
export const {
  setDashError,
  setSelected,
  setCurrentDash,
  addModalContent,
  resetModalContent,
  setSelectedMonth,
  setUpdatedTime,
  setFacilityFilter,
  setDashMenuExpanded,
  setMonthIndexMap,
  setHamburgerOpen,
  setAllIndicators,
} = dashboardSlice.actions;

const formatPatient = (patient, backLoc) => ({
  id: patient.id,
  name: `${patient.firstName} ${patient.lastName}`,
  acuityScore: patient.acuityLevels[0].acuityScore,
  riskGroup: patient.acuityLevels[0].riskGroup,
  instanceCount: patient.instanceCount,
  backLoc: backLoc || 'dashboard',
});

/**
 * Takes a positive integer and returns the corresponding column name.
 * @param {number} column  The positive integer to convert to a column name.
 * @return {string}  The column name.
 */
function columnToLetter(column) {
  let temp;
  let letter = '';
  while (column > 0) {
    temp = (column - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    column = (column - temp - 1) / 26;
  }
  return letter;
}

const styledXLSX = (data, indicators) => {
  /* Build up a worksheet from your data */
  const firstDash = data[Object.keys(data)[0]];
  const dates = [''].concat(Object.keys(firstDash));
  // Object.values(data)[0].forEach((item) => dates.push(item.createdAt));
  const ws = XLSX.utils.aoa_to_sheet([dates]);
  const totalCols = dates.length;
  let totalRows = 1; // header row counts

  const rows = [];
  const sectionRows = [];
  Object.entries(data).forEach(([dashName, values]) => {
    rows.push({ '': dashName.toUpperCase() });
    totalRows += 1;
    sectionRows.push(totalRows);
    const intermediateRows = [];
    Object.keys(values).forEach((date) => {
      const indicatorList = values[date];
      const prepDataPoints = {};
      indicatorList.forEach((indicator, index) => {
        const { displayTitle } = indicators[dashName][index];
        const { value, format } = indicator;
        let val = value;
        if (val !== 'N/A') {
          if (format === 'PERCENTAGE') val = `${val.toFixed(1)}%`;
          else if (format === 'MONEY') val = `$${val.toFixed(1)}`;
          else if (format === 'DOUBLE') val = val.toFixed(1);
        }
        prepDataPoints[displayTitle] = val;
      });

      const dataPoints = Object.keys({ ...prepDataPoints });
      dataPoints.sort(); // ie ['deathsLast30', 'erEpisode']
      for (let i = 0; i < dataPoints.length; i += 1) {
        if (!intermediateRows[i]) {
          intermediateRows[i] = { '': dataPoints[i] };
        }
        intermediateRows[i][date] = prepDataPoints[dataPoints[i]];
      }
    });
    totalRows += intermediateRows.length;
    rows.push(...intermediateRows);
  });

  XLSX.utils.sheet_add_json(ws, rows);

  ws['!sheetFormat'] = {
    row: {
      hpt: 40, // height of each row
    },
  };

  if (!ws['!cols']) ws['!cols'] = [];
  for (let i = 0; i < totalCols; i += 1) {
    ws['!cols'][i] = { auto: 1 }; // set columns to auto-fit width
  }

  /* headers styling */
  XLSX.utils.sheet_set_range_style(ws, `A1:${columnToLetter(totalCols)}1`, {
    bold: true,
    fgColor: { rgb: '25438f' },
    color: { rgb: 'ffffff' },
    top: { style: 'medium' },
    bottom: { style: 'medium' },
    left: { style: 'thin' },
    right: { style: 'thin' },
    incol: { style: 'thin' },
    inrow: { style: 'medium' },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  });

  // alternating background color and section rows
  for (let i = 2; i < totalRows + 1; i += 1) {
    if (sectionRows.includes(i)) {
      XLSX.utils.sheet_set_range_style(ws, `A${i}:${columnToLetter(totalCols)}${i}`, {
        bold: true,
        top: { style: 'medium' },
        bottom: { style: 'medium' },
        left: { style: 'thin' },
        right: { style: 'thin' },
        fgColor: { rgb: '8493BF' },
        color: { rgb: 'ffffff' },
        alignment: {
          vertical: 'center',
        },
      });
    } else {
      const color = i % 2 === 0 ? 'E0EFFF' : 'BACEFF';
      XLSX.utils.sheet_set_range_style(ws, `A${i}:${columnToLetter(totalCols)}${i}`, {
        fgColor: { rgb: color },
        top: { style: 'medium' },
        bottom: { style: 'medium' },
        left: { style: 'thin' },
        right: { style: 'thin' },
        incol: { style: 'thin' },
        inrow: { style: 'medium' },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      });
    }
  }
  // top-left empty cell set to white
  ws.A1.s = { fgColor: { rgb: 'ffffff' } };

  return ws;
};

export const postDashboardXLSX = (dashData) => async () => {
  const { months, data } = dashData;
  try {
    const res = await downloadXLSX(data, months);
    if (res.ok) {
      const resJ = await res.json();
      const wb = XLSX.utils.book_new();
      Object.entries(resJ).forEach(([sheetName, sheetData]) => {
        XLSX.utils.book_append_sheet(wb, styledXLSX(sheetData, data), sheetName.substring(0, 29));
      });
      XLSX.writeFile(wb, 'monthlyData.xlsx', { cellStyles: true });
    } else {
      setShowNotification('Error downloading Spreadsheet. Please Try Again.');
    }
  } catch (error) {
    log.error(error);
    setShowNotification(`Error downloading Spreadsheet. ${error.message}`);
  }
};

export const getModalPatientList =
  (idList, item, modalTitle, backLoc, allowDuplicatePatients) => async (dispatch) => {
    // setting this here to initially open the modal and show the spinner while content loads
    dispatch(setSelected({ item, modalTitle, optionalParams: { allowDuplicatePatients } }));
    try {
      const modalContent = [];
      const callback = async (list) => {
        const res = await postPatientListAPI({
          ...list.patientList,
          ...{ allowDuplicatePatients },
        });
        if (res.ok) {
          setDashError(undefined);
          const json = await res.json();
          json.sort((a, b) => b.acuityLevels[0].acuityScore - a.acuityLevels[0].acuityScore);
          modalContent.push({
            display: 'list',
            content: {
              list: json.map((patient) => formatPatient(patient, backLoc)),
              title: list.listTitle,
              notClickable: list.notClickable,
            },
          });
        } else {
          dispatch(setDashError('Could not fetch data.'));
        }
      };
      asyncForEach(idList, callback).then(() => {
        dispatch(addModalContent(modalContent));
      });
      // .then(() => { dispatch(setSelected({ item, modalTitle })); });
    } catch (e) {
      log.error(e);
      dispatch(setDashError('Could not fetch data.'));
    }
  };

export const getUpdatedTime = (id) => async (dispatch) => {
  try {
    const res = await getRecentOrgStatus(id);
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setUpdatedTime(resJ));
    } else {
      log.error('error fetching updated time');
    }
  } catch (error) {
    log.error(error);
  }
};

// called upon login
export const getIndicators = () => async (dispatch) => {
  try {
    const res = await getAllIndicators();
    if (res.ok) {
      const resJ = await res.json();

      const allIndicators = resJ;

      // adding cohorts to indicators
      allIndicators.cohorts = {
        Personal: [],
        Shared: [],
        Action: [],
      };

      dispatch(setAllIndicators(allIndicators));
    } else {
      log.error('error fetching all indicators');
    }
  } catch (e) {
    log.error(e);
  }
};
