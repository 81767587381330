import { useState, useEffect } from 'react';

import { getLogger } from '@util/logger';

import { postPatientListAPI } from '@api';

import { Text, layout, Icon, SpinnerOrError } from '@intus-ui';
import ResultTable from './ResultTable';
import FilterSummary from './FilterSummary';

const log = getLogger('ResultView');

const TitleRowElement = ({ patientsList }) => {
  return (
    <div
      style={{
        ...layout.rowContainer,
        flex: 'none',
        alignItems: 'center',
        gap: 10,
      }}
    >
      <Text type="title" color="navy">
        Results
      </Text>
      <div
        style={{
          ...layout.rowContainer,
          flex: 'none',
          alignItems: 'center',
        }}
      >
        <Icon name="People" color="#9D9D9D" />
        <Text type="body" color="caption">
          {patientsList?.length}
        </Text>
      </div>
    </div>
  );
};

const ResultView = ({ searchState, setSearchState }) => {
  const { filter, data, isEditMode, existingCohort } = searchState;
  const { patientIds } = data;
  const [patientsList, setPatientsList] = useState();
  const [fetchError, setFetchError] = useState();

  const formatPatient = (patient) => {
    return {
      ...patient,
      id: patient.id,
      name: `${patient.firstName} ${patient.lastName}`,
      riskGroup: patient.riskGroup ?? patient.acuityLevels[0]?.riskGroup,
      acuityScore: (patient.acuityLevels[0] || {}).acuityScore,
      team: patient.team,
      facility: patient.facility,
      acuity: { riskGroup: (patient.acuityLevels[0] || {}).riskGroup },
    };
  };

  // TODO: THIS SHOULD USE THE HELPER FUNCTION
  useEffect(() => {
    postPatientListAPI({ patientList: patientIds })
      .then(async (res) => {
        if (res.ok) {
          const json = await res.json();
          const pList = json.map((patient) => formatPatient(patient));
          setPatientsList(pList);
        } else {
          setFetchError('Internal server error - unable to fetch data.');
          log.error(res);
        }
      })
      .catch((e) => {
        setFetchError('Internal server error - unable to fetch data.');
        log.error(e);
      });
  }, [searchState]);

  return (
    <>
      <div style={{ ...layout.quad, flex: 'none', gap: 15, padding: '15px 30px 15px 30px' }}>
        <FilterSummary
          filter={filter}
          setSearchState={setSearchState}
          searchState={searchState}
          isEditMode={isEditMode}
          existingCohort={existingCohort}
        />
      </div>

      {patientsList ? (
        <ResultTable
          participants={patientsList}
          titleRowElement={<TitleRowElement patientsList={patientsList} />}
          searchState={searchState}
        />
      ) : (
        <SpinnerOrError error={fetchError} />
      )}
    </>
  );
};

export default ResultView;
