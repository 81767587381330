/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { postPatientAPI, getActivePatients, getInactivePatients } from '@api/api';
import riskBreakup from '@util/riskGrouping';
import { getYearOfDate } from '@util/dateFunctions';
import { getLogger } from '@util/logger';
import { getSessionUser } from '@util/session';

const log = getLogger('patientListSlice');

const patientListSlice = createSlice({
  name: 'patientListSlice',
  initialState: {
    patients: undefined, // active patients
    error: undefined,
    filteredPatients: undefined,
    riskFilter: 'All',
    disenrolledPatients: undefined,
    livingSituations: undefined,
  },
  reducers: {
    // The payload should be a list of patientListPatientPropType.
    setPatients(state, action) {
      state.patients = action.payload;
    },

    // The payload should be a filtered list of patientListPatientPropType
    setFilteredPatients(state, action) {
      state.filteredPatients = action.payload;
    },

    // Keeps track of which risk group the list is currently filtered by.
    setRiskFilter(state, action) {
      state.riskFilter = action.payload;
    },

    // This allows the patient list to display an error.
    setError(state, action) {
      state.error = action.payload;
    },

    // This is triggered together with the retry button.
    clearPatientsAndError(state) {
      state.patients = undefined;
      state.error = undefined;
    },

    // The payload should be a patientListPatientPropType.
    addPatientToState(state, action) {
      state.patients.push(action.payload);
    },
    setDisenrolledPatients(state, action) {
      state.disenrolledPatients = action.payload;
    },

    setLivingSituation(state, action) {
      state.livingSituations = action.payload;
    },
  },
});

export default patientListSlice.reducer;
export const {
  setPatients,
  setError,
  clearPatientsAndError,
  addPatientToState,
  setFilteredPatients,
  setRiskFilter,
  setDisenrolledPatients,
  setLivingSituation,
} = patientListSlice.actions;

export const getPatients = () => async (dispatch) => {
  dispatch(clearPatientsAndError());
  const errorMessage = 'There was a problem fetching the participants for this organization.';
  const allPatients = [];
  try {
    const active = await getActivePatients();
    if (active.ok) {
      const json = await active.json();
      allPatients.push(
        json.map((data) => {
          return { ...data };
        })
      );
      dispatch(
        setPatients(
          json.map((data) => {
            return {
              ...data,
              id: data.id,
              firstName: data.firstName,
              lastName: data.lastName,
              acuityScore: (data.acuityLevels[0] || {}).acuityScore,
              riskGroup: (data.acuityLevels[0] || {}).riskGroup,
              team: data.team,
              facility: data.facility,
              livingSituation: (data.acuityLevels[0] || {}).livingSituation,
              age: getYearOfDate(data.dateOfBirth),
              instanceCount: data.instanceCount,
              organizationId: data.organizationId,
            };
          })
        )
      );

      dispatch(
        setFilteredPatients(
          json.map((data) => ({
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            acuityScore: (data.acuityLevels[0] || {}).acuityScore,
            riskGroup: (data.acuityLevels[0] || {}).riskGroup,
            team: data.team,
            facility: data.facility,
            livingSituation: (data.acuityLevels[0] || {}).livingSituation,
            age: getYearOfDate(data.dateOfBirth),
            instanceCount: data.instanceCount,
            organizationId: data.organizationId,
          }))
        )
      );
    } else {
      dispatch(setError(errorMessage));
    }
    const inactive = await getInactivePatients();
    if (inactive.ok) {
      const json = await inactive.json();
      allPatients.push(
        json.map((data) => {
          return { ...data };
        })
      );
      dispatch(
        setDisenrolledPatients(
          json.map((data) => ({
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            team: data.team,
            facility: data.facility,
            disenrollmentDate: data.disenrollmentDate,
            disenrollmentReason: data.disenrollmentReason,
            livingSituation: (data.acuityLevels[0] || {}).livingSituation,
            age: getYearOfDate(data.dateOfBirth),
            instanceCount: data.instanceCount,
            organizationId: data.organizationId,
          }))
        )
      );
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
    dispatch(setError(errorMessage));
  }
  return allPatients;
};

export const addPatient = (patient) => async (dispatch) => {
  const user = getSessionUser();
  try {
    const body = {
      firstName: patient.firstName,
      lastName: patient.lastName,
      address: {
        street: patient.addressLine1,
        address2: patient.addressLine2,
        city: patient.city,
        state: patient.state,
        zip: patient.zip,
      },
      phoneNumber: patient.phoneNumber,
      dateOfBirth: patient.dateOfBirth,
      organizationId: user.organizationId,
    };
    const result = await postPatientAPI(body);
    if (result.ok) {
      const { id } = await result.json();
      dispatch(
        addPatientToState({
          id,
          firstName: patient.firstName,
          lastName: patient.lastName,
          riskGroup: undefined,
          acuityScore: undefined,
        })
      );
    }
  } catch (err) {
    log.error(err);
  }
};

export const filterPatients = (riskGroup, list) => async (dispatch) => {
  dispatch(setRiskFilter(riskGroup));
  if (riskGroup === riskBreakup.HIGHEST.name) {
    dispatch(
      setFilteredPatients(list.filter((patient) => patient.riskGroup === riskBreakup.HIGHEST.enum))
    );
  }
  if (riskGroup === riskBreakup.HIGH.name) {
    dispatch(
      setFilteredPatients(list.filter((patient) => patient.riskGroup === riskBreakup.HIGH.enum))
    );
  }
  if (riskGroup === riskBreakup.MODERATE.name) {
    dispatch(
      setFilteredPatients(list.filter((patient) => patient.riskGroup === riskBreakup.MODERATE.enum))
    );
  }
  if (riskGroup === riskBreakup.LOW.name) {
    dispatch(
      setFilteredPatients(list.filter((patient) => patient.riskGroup === riskBreakup.LOW.enum))
    );
  }
  if (riskGroup === riskBreakup.ALL.name) {
    dispatch(setFilteredPatients(list));
  }
};
