import React, { useState, useEffect, isValidElement } from 'react';
import { useDispatch } from 'react-redux';

import { flatten, orderBy, uniq, uniqBy } from 'lodash';

import { getStringReplacement } from '@util/stringReplacements';
import { setCurrPatient, setPatientList } from '@global-state/redux/profileSlice';

import { List } from '@intus-ui';

import { ParticipantListProvider, useParticipantListContext } from './Context';
import EnrollFilter from './filters/EnrollFilter';
import { formatParticipants } from './helpers';
import { defaultColumnConfig } from './list/columnConfigs';
import { getParticipantListColumns } from './list/format';

/**
 *
 * @param {* A list of strings representing elements in the elementMap} elements
 * @param {* Maps element names to their corresponding jsx element } elementMap
 * @returns a jsx element to pass into the topRightElements slot in the List component
 */
const renderTopRightElements = (elements, elementMap) => {
  return (
    <div style={styles.topRightElements}>
      {elements.map((element) => {
        if (typeof element === 'string') return elementMap[element];
        if (isValidElement(element)) return element;
        return null;
      })}
    </div>
  );
};

const ParticipantListContents = ({
  participantList,
  customFormat,
  customColumnConfig, // customColumnConfig object should have a key named default
  onClickItem,
  searchable,
  loading,
  topRightElementsList,
  defaultSort,
  titleRowElement,
  emptyListMessage,
  showColumnHeaders,
  showDisenrolledParticipants,
  style,
  listStyle,
  customListItem,
  extraRenders,
  virtualizedOptions,
  noPadding,
}) => {
  const dispatch = useDispatch();

  const { setFilterOptions } = useParticipantListContext();

  // columnConfig will change based off of value of enroll filters
  const [columnConfig, setColumnConfig] = useState(customColumnConfig?.default);
  // default state is enrolled ppts, filtering out disenrolled ppts
  const [data, setData] = useState(formatParticipants(participantList));
  const [sortedData, setSortedData] = useState([]);
  let customSort = defaultSort;
  if (showDisenrolledParticipants) customSort = { field: undefined, direction: 'none' };

  // actually update columns if they change dynamically
  useEffect(() => {
    setColumnConfig(customColumnConfig?.default);
  }, [customColumnConfig]);

  useEffect(() => {
    setData(formatParticipants(participantList));
  }, [participantList]);

  // setting filter dropdown options based on available data
  useEffect(() => {
    const allCohorts = flatten(data?.map((item) => item.cohorts ?? []));

    const uniqueCohorts = orderBy(uniqBy(allCohorts, 'id'), (c) => c.name.toLowerCase());

    setFilterOptions({
      livingSituation: ['All', ...uniq(data?.map((item) => item.livingSituation))],
      cohorts: uniqueCohorts,
    });
  }, [data]);

  // maps an element name to its corresponding jsx element
  const topRightElementsMap = {
    enrollFilter: (
      <EnrollFilter
        key="enrollFilter"
        columnConfig={customColumnConfig || defaultColumnConfig}
        setColumnConfig={setColumnConfig}
      />
    ),
  };

  let lowlightItems = null;
  let defaultFilter = {
    type: 'enroll',
    value: (item) => {
      return item?.disenrollDate === 'Invalid Date';
    },
  };

  if (showDisenrolledParticipants) {
    defaultFilter = null;
    lowlightItems = (item) => item.disenrollDate !== 'Invalid Date';
  }

  return (
    <div style={{ ...styles.listWrapperStyle, ...style }}>
      <List
        data={data}
        listStyle={listStyle}
        loading={loading}
        searchable={searchable}
        format={customFormat}
        columnConfig={columnConfig}
        onClickItem={(item) => {
          const abbreviatedPpts = sortedData?.map((patient) => ({
            id: patient.id,
            name: patient.name || `${patient.firstName} ${patient.lastName}`,
          }));
          const currIndex = abbreviatedPpts?.findIndex((ppt) => ppt.id === item.id);
          dispatch(setPatientList(abbreviatedPpts));
          dispatch(setCurrPatient(currIndex));
          onClickItem(item);
        }}
        defaultSort={customSort}
        titleRowElement={titleRowElement}
        topRightElements={
          topRightElementsList && renderTopRightElements(topRightElementsList, topRightElementsMap)
        }
        emptyListMessage={emptyListMessage}
        showColumnHeaders={showColumnHeaders}
        defaultFilter={defaultFilter}
        sortedListPassthrough={setSortedData}
        lowlightItems={lowlightItems}
        customListItem={customListItem}
        extraRenders={extraRenders}
        virtualizedOptions={virtualizedOptions}
        noPadding={noPadding}
      />
    </div>
  );
};

const ParticipantList = ({
  participantList = [],
  customFormat = getParticipantListColumns(),
  customColumnConfig = defaultColumnConfig,
  onClickItem,
  searchable = true,
  topRightElementsList = undefined,
  defaultSort = { field: 'acuity', direction: 'desc' },
  titleRowElement,
  emptyListMessage = `No ${getStringReplacement('Participant', {
    plural: true,
    lower: true,
  })} in this list`,
  showColumnHeaders,
  loading,
  style,
  listStyle,
  customListItem,
  extraRenders,
  showDisenrolledParticipants,
  // Options for react-window's FixedSizeList component. See https://react-window.now.sh/#/api/FixedSizeList
  // Height and itemSize are required. itemSize is the height of each row including padding/margin.
  virtualizedOptions,
  // True to remove padding from the list
  noPadding,
}) => {
  return (
    <ParticipantListProvider>
      <ParticipantListContents
        customFormat={customFormat}
        customColumnConfig={customColumnConfig}
        onClickItem={onClickItem}
        topRightElementsList={topRightElementsList}
        participantList={participantList}
        searchable={searchable}
        defaultSort={defaultSort}
        titleRowElement={titleRowElement}
        emptyListMessage={emptyListMessage}
        showColumnHeaders={showColumnHeaders}
        loading={loading}
        style={style}
        listStyle={listStyle}
        customListItem={customListItem}
        extraRenders={extraRenders}
        showDisenrolledParticipants={showDisenrolledParticipants}
        virtualizedOptions={virtualizedOptions}
        noPadding={noPadding}
      />
    </ParticipantListProvider>
  );
};

export default ParticipantList;

const styles = {
  listWrapperStyle: {
    display: 'flex',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  topRightElements: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '25px',
  },
};
