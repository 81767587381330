class ParticipantMapper {
  static apiToDomain(apiParticipant) {
    return Object.freeze({
      id: apiParticipant?.id,
      name: `${apiParticipant?.firstName} ${apiParticipant?.lastName}`,
      status: apiParticipant?.status,
      dateOfBirth: apiParticipant?.dateOfBirth,
      gender: apiParticipant?.gender,
      enrollmentDate: apiParticipant?.enrollmentDate,
      disenrollmentDate: apiParticipant?.disenrollmentDate,
      disenrollmentReason: apiParticipant?.disenrollmentReason,
      livingSituation: apiParticipant?.acuityLevels[0]?.livingSituation,
      acuity: {
        score: apiParticipant?.acuityLevels[0]?.acuityScore,
        riskGroup: apiParticipant?.acuityLevels[0]?.riskGroup,
      },
      instanceCount: apiParticipant?.instanceCount,
    });
  }
}

const ParticipantsApi = {
  tagTypes: ['Participant'],
  endpoints: (builder) => {
    return {
      getParticipant: builder.query({
        query: (participantId) => `/patients/${participantId}`,
        transformResponse: (res) => ParticipantMapper.apiToDomain(res),
      }),
      getActiveParticipants: builder.query({
        query: () => ({
          url: '/patients?status=ACTIVE',
          method: 'GET',
        }),
        transformResponse: (res) => {
          return res.map((r) => ParticipantMapper.apiToDomain(r));
        },
      }),
      getParticipants: builder.query({
        query: (participantIds = []) => ({
          url: '/patients/list',
          method: 'POST',
          body: {
            patientList: participantIds,
          },
        }),
        transformResponse: (res) => {
          return res.map((r) => ParticipantMapper.apiToDomain(r));
        },
      }),
    };
  },
};

export default ParticipantsApi;
